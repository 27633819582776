@import '~primeicons/primeicons.css';

.p-fileupload-buttonbar .p-fileupload-choose input{
    display: none !important;
}

.gap-3{
    display: none !important;
}

.p-fileupload-choose span{
    margin-left: 10px;
}

.p-fileupload{
    width: 70%;
    border-radius: 10px;
    margin-top: 100px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.p-fileupload-row img{
    display: none;
    
}