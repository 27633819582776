.sidebar{
    height: calc(100vh - 50px);
    position: fixed !important;
    left: 0px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
    width: 220px !important;
    z-index: 2;
    background-color: white !important;
    padding-top: 30px !important;
    top: 50px !important;
    margin-left: 0px !important;
}

li:has(> .is-active){
    background-color: #448aff;
}

.is-active{
    color: white !important;
}

.sidebar p {
    font-weight: 600;
    color: #4285f4;
    padding: 25px;
}
li:hover{
    background-color: #4285f4;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    color: black;
} 
.sidebar ul li {
    padding-left: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    cursor: pointer;
}
  
  
.sidebar ul li a {
    text-decoration: none;
    color: #000;
}

.sidebar li span {
    padding-left: 20px;
}

.sidebar ul{
    padding-left: 0px !important;
}
.sidebar ul li:last-child {
    margin-top: auto; /* Pushes the last item to the bottom */
  }
  .bottom-profile{
    margin-top: auto;
  }
  @media (max-width: 450px) {
    .bottom-profile {
        margin-top: auto;
        margin-bottom: 3rem;
    }
}