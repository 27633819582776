
.form-body{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    
}
form{
    box-shadow: 10px 0px 20px rgba(132, 132, 132, 0.353);
    border-radius: 20px 30px /  10px 5%;
    /* margin-top: 8%; */
    width: 75%;
    padding: 50px;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.7);
}
.secodaryLogIn{
    width: 75%;
    box-shadow: 10px 0px 20px rgba(132, 132, 132, 0.353);
    padding: 1rem;
    margin: 1rem;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px 5% / 20px 30px;
}

.login-card{
    position: relative;
    z-index: 2;
}
.form-input{
    display: flex;
    flex-direction: column;
    gap: 2px;
    
}
.form-input input{
    padding: 0.7rem;
    width: 100%;
}
input:focus {
    outline: none;
    border-color: #4c9aff;
    box-shadow: 0 0 3px #4c9aff;
    
  }
.form-input label{
    text-align: left;
    font-size: large;
}
.input-field{
    display: flex;
    align-items: center;
    width: 100%;
}
.password-container{
    display: flex;
    justify-content: space-between;
}
.showpassword{
    display: flex;
    align-items: center;

}
.checkbox{
    padding: 8px;
}
.forgot-password{
    color:rgb(239, 68, 68);
}
.form-input button{
    font-size: 1.2rem;
   border: none;
   border-radius: 10px;
    font-weight: 600;
    color: white;
   background-color: rgb(113, 129, 246);
   padding: 1.2rem 0rem;
   
}
.form-input button:hover{
    background-color: black;
}
.end-text a {
    text-decoration: none;
}
.loginSms{
    display: flex;
    align-items: center;
    justify-content: center;
  font-size: 1.2rem;
  color:rgb(104, 102, 102); 
  font-weight: 400;
}
.loginSms:hover{
    color: rgb(33, 150, 243);
}
.signup-btn {
   color:rgb(104, 102, 102);
   font-size: 1.7rem;
   font-weight: 500;
}
@media only screen and (max-width: 600px) {
   form{
    width: 90%;
    padding:50px 20px
   }
   .secodaryLogIn{
    width: 90%;
}
   .form-input button{
    padding: 0.7rem 0rem;
   }
  }