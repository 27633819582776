.dateFilter{
    border: rgb(218, 217, 217) 1px solid;
    padding: 6px;
    border-radius: 5px;
}

.filter-wrapper{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

.filter-component{
    width: 90%;
    padding: 20px;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    border: 1px rgb(205, 203, 203) solid;
    border-radius: 10px;
}

.multiselect{
    width: 275px;
    
}

.multiselect-dropdown{
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 4px;
}

.multiselect-dropdown-list div{
    float: left;
    width: 100%;
}
.multiselect-dropdown-list input{
    margin-left: 20px;
}

.multiselect-dropdown span.placeholder{
    background-color: white;
    color: black;
    cursor:pointer;
}

.optext{
    background-color: white !important;
}

.multiselect-dropdown-list label, .multiselect-dropdown-list input{
    float: left;
    
}


@media (max-width: 615px) {
    .filter-wrapper{
        justify-content: center;
    }
}