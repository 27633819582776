.background-header {
    height: 170px;
  }
  .background-header-color {
    height: 170px;
    position: absolute;
  }
  .background-header-img {
    background-image: url(../../assetes/trainBackgroundImage.jpg);
    width: 100%;
    height: 100%;
    background-size: cover;
  }