.page-body{
    margin-top: 80px;
}
@media screen and (max-width: 500px) {
    .total-table-div {
        margin-top: 5rem !important;
    }
}


.train-details-1 tr:nth-child(odd) td:nth-child(1),
.train-details-1 tr:nth-child(odd) td:nth-child(2){
    background-color: #eaeaea; 
}

.train-details-1 tr:nth-child(odd) td:nth-child(3){
    background-color: rgb(131, 232, 131);
}

.train-details-1 tr:nth-child(odd) td:nth-child(4){
    background-color: rgb(238, 182, 182);
}


.train-details-1 tr:nth-child(even) td:nth-child(3){
    background-color: rgb(176, 234, 176);
}

.train-details-1 tr:nth-child(even) td:nth-child(4){
    background-color: rgb(240, 208, 208);
}

.train-details-1 tr:nth-child(even) td:nth-child(1),
.train-details-1 tr:nth-child(even) td:nth-child(2){
    background-color: white; 
}