.Edit-form {
  width: 68% !important;
}

.form-group row my-3 label {
  margin-right: -30%;
}

.btn-current {
  text-align: center;
  align-items: end;
  margin-top: -1px !important;
}

.btn-station {
  margin-left: 2%;
}

.form-input {
  padding: 4px 16px;
}

.form-control {
  width: 100% !important;
}

.form-control {
  font-size: 14px;
  border-radius: 2px;
  border: 1px solid #cccccc;
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
}

.Editprofile-body button {
  width: 90%;
}

/*UserProfile*/
.text-muted {
  text-transform: capitalize;
}

.user-section {
  background-color: transparent;
}

.fixed-width-button {
  width: 300px;
  height: 55px;
}

.fixed-width-btn {
  width: 250px;
  height: 40px;
}



.Editprofile-body {
  background-color: #ffffff;
  border-radius: 0 !important;
  box-shadow: 20px 10px 20px rgba(132, 132, 132, 0.353);
  padding-top: 10px;
  padding-bottom: 2px;
  /* margin-top: 8px; */
  margin-right: 0px !important;
}

.Edit-form {
  width: 68% !important;
}

.form-group row my-3 label {
  margin-right: -30%;
}

.btn-current {
  text-align: center;
  align-items: end;
  margin-top: -1px !important;
}

.btn-station {
  margin-left: 2%;
}

.form-input {
  padding: 4px 16px;
}

.form-control {
  width: 100% !important;
}

.form-control {
  font-size: 14px;
  border-radius: 2px;
  border: 1px solid #cccccc;
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
}

.Editprofile-body button {
  width: 90%;
}

#coach-number{
  max-width: 100% !important;
}

@media screen and (max-width: 1200px) {
  .Edit-body {
    display: flex;
    flex-direction: column !important;
  }

  .Editprofile-body {
    width: 100% !important;
  }

  .Edit-form {
    width: 100% !important;
    margin-top: 20px !important;
  }

  .Edit-form form {
    margin-right: 0px !important;
    margin-left: 0px;
  }
}