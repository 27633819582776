.Navbar{
    height: 50px;
    background-color: #448aff;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    z-index: 10;
    display: flex;
}