.form-body {
    position: relative;
    z-index: 2;
    background-color: white;
    background: transparent;
    padding-bottom: 3%;
    padding-top: 3%;
    padding-left: 3% !important;
    padding-right: 3% !important;
    
    border-radius: 30px;
    background-image: url(
        '../../assetes/trainImage.webp');
    background-position: center;
    background-repeat: no-repeat;
width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.formHere{
    background-color: rgba(255, 255, 255, 0.7);
}

.name-row {
    display: flex;
    flex-wrap: wrap;
}

.name-row .form-input {
    margin-right: 10px;
    width: 320px;
}

.main-header {
    position: relative;
    z-index: 2;
}

.register-wrapper {
    height: 100%;
    padding: 1% 5% 5% 5%;
}

.register-back {
    background-color: #69a1ff;
    height: 180px;
    top: 0px;
    left: 0px;
    width: 100%;
    position: fixed;
    z-index: 2;
}

.register-back-lower {
    background-color: #edf4ff;
    height: calc(100vh - 180px);
    top: 180px;
    left: 0px;
    width: 100%;
    position: fixed;
    z-index: 0;
}

.signup-btn {
    padding: 1.5%;
    border: none;
    font-size: larger;
    background-color: rgb(113, 129, 246);
    color: white;
    border-radius: 10px;
}

[type='submit']:hover {
    background-color: black;
    cursor: pointer;
    user-select: none;
}

.hide {
    display: none;
}

.show password {
    display: flex;
    justify-content: space-between;
}

.a-login {
    text-decoration: none;
    color: #000;
    font-weight: 600;
}

.register-wrapper label {
    font-size: large;
    margin-top: 10px;
    width: fit-content;
    float: left;
}

.show-password {
    display: flex;
    justify-content: space-between;
}

.container .vscomp-ele{
    display: block;
    max-width: 100%;
}
