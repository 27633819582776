.table-wrapper{
    /* padding-top: 50px; */
    width: 90%;
}

.hidden-row {
    display: none;
}

table thead{
    background-color: #2b50a1;
    color: white;
}

table th{
    padding: 6px;
    border-width: 0.5px;
    text-align: center !important;
}

table td{
    padding: 6px;
    text-align: center;
    /* border-width: 0.5px; */
}

table .odd{
    background-color: #d6e0f5 !important;
}

.dataTable{
    width: 100%;
}

.dataTables_wrapper{
    width: 100%;
}

.custom-class-column-1{
    max-width: 20px !important;
}

.custom-class-column-2{
    max-width: 30px !important;
}

.custom-class-column-3{
    max-width: 70px !important;
}


.train-details-2 tr:nth-child(odd) td:nth-child(1),
.train-details-2 tr:nth-child(odd) td:nth-child(2){
    background-color: #eaeaea; 
}

.train-details-2 tr:nth-child(odd) td:nth-child(3),
.train-details-2 tr:nth-child(odd) td:nth-child(6) {
    background-color: rgb(131, 232, 131);
}

.train-details-2 tr:nth-child(odd) td:nth-child(4),
.train-details-2 tr:nth-child(odd) td:nth-child(7) {
    background-color: rgb(238, 182, 182);
}

.train-details-2 tr:nth-child(odd) td:nth-child(5),
.train-details-2 tr:nth-child(odd) td:nth-child(8) {
    background-color: rgb(131, 204, 253);
}

.train-details-2 tr:nth-child(even) td:nth-child(3),
.train-details-2 tr:nth-child(even) td:nth-child(6) {
    background-color: rgb(176, 234, 176);
}

.train-details-2 tr:nth-child(even) td:nth-child(4),
.train-details-2 tr:nth-child(even) td:nth-child(7) {
    background-color: rgb(240, 208, 208);
}

.train-details-2 tr:nth-child(even) td:nth-child(5),
.train-details-2 tr:nth-child(even) td:nth-child(8) {
    background-color: rgb(153, 214, 255);
}

.train-details-2 tr:nth-child(even) td:nth-child(1),
.train-details-2 tr:nth-child(even) td:nth-child(2){
    background-color: white; 
}

.train-details-2 {
    border-collapse: collapse !important;
}

